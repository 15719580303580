import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div class="jfd-contributor-cards jfd-contributor-cards">
            <Link to={props.blogContent.TitleLink}> <img class="img" src={props.blogContent.ImagePath} alt="avatar" /> </Link>
            <span class="jfd-contributor-cards_rect"></span>
            <div class="jfd-contributor-cards_tri"></div>
            <div class="jfd-contributor-title-bar">
                <Link to={props.blogContent.TitleLink}> <h1 class="jfd-contributor-title">{props.blogContent.Title}</h1> </Link>
            </div>
            <ul class="jfd-contributor-social-icons">
                {props.blogContent.SocialLinks.map(item => (
                    <li>
                        <Link to={item.Link}> <i className={item.IconName}></i> </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ShortTitles;
